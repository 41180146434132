




































import { defineComponent } from "@vue/composition-api";

export default defineComponent({
  components: {
    OAddReviewMessage: () =>
      import(
        "@/components/organisms/event/abstractModule/o-add-review-message.vue"
      ),
    OAssignAbstractMessage: () =>
      import(
        "@/components/organisms/event/abstractModule/o-assign-abstract-message.vue"
      ),
    OCreateAbstractMessage: () =>
      import(
        "@/components/organisms/event/abstractModule/o-create-abstract-message.vue"
      ),
    OAbstractStatusMessage: () =>
      import(
        "@/components/organisms/event/abstractModule/o-abstract-status-message.vue"
      ),
    OAbstractAcceptMessage: () =>
      import(
        "@/components/organisms/event/abstractModule/o-abstract-accept-message.vue"
      ),
    OConditionalAcceptMessage: () =>
      import(
        "@/components/organisms/event/abstractModule/o-conditional-accept-message.vue"
      ),
    OAbstractEditMessage: () =>
      import(
        "@/components/organisms/event/abstractModule/o-abstract-edit-message.vue"
      ),
    OAbstractRejectMessage: () =>
      import(
        "@/components/organisms/event/abstractModule/o-abstract-reject-message.vue"
      ),
  },

  setup() {
    return {};
  },
});
